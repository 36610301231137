<section class="lesson-plans-comp">
  <!--container start-->
  <div class="container-fluid">

    <div class="info">{{ 'shared.lp_click_info' | translate }}</div>
    <!--row start-->
    <div class="row">
      <app-daily-lesson-table  *ngIf="showTable === 'daily'"  [school]="school" [age]="age" [week]="week" [tableData]="dailyTableData" (onSelect)="vmOnSelected($event)"></app-daily-lesson-table>
      <app-weekly-lesson-table *ngIf="showTable === 'weekly'" [school]="school" [age]="age" [week]="week" [tableData]="weeklyTableData" (onSelect)="vmOnSelected($event)"></app-weekly-lesson-table>
      <p class="copyright">{{ getCopyrite() }}</p>
    </div>
    <!--row end-->
  </div>
  <!--container end-->
</section>
