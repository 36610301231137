import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-lesson-plans',
  templateUrl: './lesson-plans.component.html',
  styleUrls: ['./lesson-plans.component.scss'],
})
export class LessonPlansViewComponent implements OnInit, OnChanges {
  
  public currentYear;
  /**
   * `Daily` Table data to show.
   */
  public dailyTableData: Array<ITableRow> = [
    {
      id: 'st',
      sortBy: 1,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'll',
      sortBy: 2,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'pd',
      sortBy: 3,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'mm',
      sortBy: 4,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },

    {
      id: 'ir',
      sortBy: 5,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    },
    {
      id: 'cg',
      sortBy: 6,
      title: null,
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
    }
  ];

  /**
   * `Weekly` Table data to show.
   */
  public weeklyTableData: IWeeklyTableData = {
    activities: [
      { id: 'st', sortBy: 1, title: null, items: [] },
      { id: 'll', sortBy: 2, title: null, items: [] },
      { id: 'pd', sortBy: 3, title: null, items: [] },
      { id: 'mm', sortBy: 4, title: null, items: [] },
      { id: 'ir', sortBy: 5, title: null, items: [] },
      { id: 'cg', sortBy: 6, title: null, items: [] }
    ],
  };

  /**
   * Flag to show table bodyt or not.
   */
  public showTable: string = null;

  /**
   * XML Data to get data for table.
   */
  private xmlData: any = null;

  /**
   * Pretty titles for activities.
   */
  private activitiesTitles: Array<{ key: string; title: string }> = null;

   /**
   * Input `school`.
   */
  @Input()
  public school: any = null;

  /**
   * Input `age parameter` from filters.
   */
  @Input()
  public age: string = null;

  /**
   * Input `week parameter` from filters.
   */
  @Input()
  public week: string = null;

  /**
   * Input `mode parameter` from filters.
   */
  @Input()
  public mode: string = null;

  /**
   * Input xml data.
   */
  @Input()
  public set xml(data: any) {
    this.xmlData = data;
  }

  @Output()
  public selected: EventEmitter<{ activity: string; day: number } | { activity: string; week: number }> = new EventEmitter();

  constructor(
    private teacherService: TeacherService, 
    private translateService: TranslateService) {

    this.activitiesTitles = this.teacherService.getActivities();
  }

  public ngOnInit(): void {
    // Get pretty title for rows.
    this.vmGetRowTitles();
    this.currentYear =  new Date().getFullYear();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.xmlData) {
      if (this.mode === 'lp') {
        this.vmCreateDailyTableData(this.xmlData);
      } else {
        this.vmCreateWeeklyTableData(this.xmlData);
      }
    } else {
      this.vmResetTableData();
    }
  }

  public vmOnSelected(e: { code: string; day?: string }): void {
    if (this.mode === 'lp') {
      this.selected.emit({ activity: e.code, day: +e.day });
    } else {
      this.selected.emit({ activity: e.code, week: +this.week });
    }
  }

  public getCopyrite(){

    let _copyright = this.translateService.instant('shared.copyright');
    let result =  _copyright.replace('2023', this.currentYear);
    return  result;
  }

 
  /**
   * Method to create data for table.
   * @param data xml data
   */
  private vmCreateDailyTableData(data: any): void {
    // Reset previous table data.
    this.vmResetTableData('daily');

    // Create data.
    data.elements.forEach((_day: { type: string; name: string; elements: any[]; attributes: { id: string } }) => {

      _day.elements.forEach((_activity: { type: string; name: string; elements: any[]; attributes: { id: string } }) => {


        let name = _activity.elements.find((x: any) => x.name === 'name' );
        let objective = _activity.elements.find((x: any) => x.name === 'objectives');

        let name_text = name.elements.map((x) => x.text );

        let allTextObjectives = [];      

        objective.elements.forEach((el) => {
          let temp = el.elements.map((x) => x.text)[0];

          if (temp !== 'Enter text here') {
            allTextObjectives.push(temp);
          }         
        })
        const text: string = name_text;

        const row: ITableRow = this.dailyTableData.find((_row: ITableRow) => _row.id === _activity.attributes.id);

        const day: any[] = row[`day${_day.attributes.id}`];

        let dddd = {
            name: name_text,
            objectives: allTextObjectives
          };

        day.push(dddd);
      });
    });

    // Filter rows that dont have data in all days.
    this.dailyTableData = this.dailyTableData.filter((_row: ITableRow) => {
      if (_row.day1.length > 0 || _row.day2.length > 0 || _row.day3.length > 0 || _row.day4.length > 0 || _row.day5.length > 0) {
        return true;
      }

      return false;
    });

    this.dailyTableData = this.vmSortData(this.dailyTableData);

    // When table data ready, show table.
    this.showTable = 'daily';
  }

  private vmCreateWeeklyTableData(data: any): void {
    // Reset previous table data.
    this.vmResetTableData('weekly');

    this.weeklyTableData.activities.forEach((_tableActivity: IWeeklyTableActivity) => {
      const xmlActivity: { type: string; name: string; elements: any[]; attributes: { id: string } } = data.elements.find(
        (_activity: { type: string; name: string; elements: any[]; attributes: { id: string } }) => _activity.attributes.id === _tableActivity.id
      );

      const items = {
        items: [],
      };

      if (!!xmlActivity && !!xmlActivity.elements) {

        let name = xmlActivity.elements.find((x: any) => x.name === 'name' );
        let objective = xmlActivity.elements.find((x: any) => x.name === 'objectives');

        let name_text = name.elements.map((x) => x.text );
  
        let allTextObjectives = [];      

        objective.elements.forEach((el) => {
          let temp = el.elements.map((x) => x.text)[0];
          
          if (temp !== 'Enter text here') {
            allTextObjectives.push(temp);
          }
        })
                                               
        items.items = [
          {
            name: name_text,
            objectives: allTextObjectives
          }
        ];
      }

      _tableActivity.items = [..._tableActivity.items, items];
    });

    // Filter activities that dont have any data to show.
    this.weeklyTableData.activities = this.weeklyTableData.activities.filter((_activity: IWeeklyTableActivity) => {
      return !_activity.items.every((_item) => _item.items.length === 0);
    });

    this.weeklyTableData.activities = this.vmSortData(this.weeklyTableData.activities);

    this.showTable = 'weekly';
  }

  private vmSortData(data: Array<ITableRow> | Array<IWeeklyTableActivity>): any {
    return data.sort((a, b) => {
      return a.sortBy > b.sortBy ? 1 : -1;
    });
  }

  /**
   * Method to reset table data.
   * @param  table 'Type' of table data to reset, if not set all tables will be reseted.
   */
  private vmResetTableData(table?: 'daily' | 'weekly'): void {
    switch (table) {
      case 'daily': {
        this.dailyTableData = [
          {
            id: 'st',
            sortBy: 1,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          },
          {
            id: 'll',
            sortBy: 2,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          },
          {
            id: 'pd',
            sortBy: 3,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          },
          {
            id: 'mm',
            sortBy: 4,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          },

          {
            id: 'ir',
            sortBy: 5,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          },
          {
            id: 'cg',
            sortBy: 6,
            title: null,
            day1: [],
            day2: [],
            day3: [],
            day4: [],
            day5: [],
          }
        ];

        break;
      }
      case 'weekly': {
        this.weeklyTableData = {
          activities: [
            { id: 'st', sortBy: 1, title: null, items: [] },
            { id: 'll', sortBy: 2, title: null, items: [] },
            { id: 'pd', sortBy: 3, title: null, items: [] },
            { id: 'mm', sortBy: 4, title: null, items: [] },
            { id: 'ir', sortBy: 5, title: null, items: [] },
            { id: 'cg', sortBy: 6, title: null, items: [] }
          ],
        };
        break;
      }
      default: {
        this.vmResetTableData('daily');
        this.vmResetTableData('weekly');
        break;
      }
    }
    this.vmGetRowTitles();
  }

  /**
   * method to get pretty titles for table rows.
   */
  private vmGetRowTitles(): void {
    this.dailyTableData.forEach((_row: ITableRow) => {
      _row.title = this.activitiesTitles.find((_activity) => _activity.key === _row.id).title;
    });

    this.weeklyTableData.activities.forEach((_activity) => {
      _activity.title = this.activitiesTitles.find((_a) => _a.key === _activity.id).title;
    });
  }
}

export interface ITableRow {
  id: string;
  sortBy: number;
  title: string;
  day1: string[];
  day2: string[];
  day3: string[];
  day4: string[];
  day5: string[];
}

export interface IWeeklyTableData {
  activities: Array<IWeeklyTableActivity>; // row
}

export interface IWeeklyTableActivity {
  id: string;
  title: string;
  items: Array<{ items: string[] }>;
  sortBy: number;
}
