
<!--table start-->
<table class="table">
  <thead>
    <tr>
      <td colspan="2">
        <div class="print-title">
          <div>{{'weekly_overview.title' | translate}}</div>
          <div class="dash" *ngIf="age">–</div>
          <div *ngIf="age">{{ ageTitle | translate}}</div>
          <div class="dash" *ngIf="age && week">–</div>
          <div *ngIf="week">{{'shared.week' | translate}} {{ week }}</div>
        </div>
      </td>
    </tr>
  </thead>

  <tbody>
    <div class="tr" *ngFor="let activity of tableData.activities" [ngClass]="{'show_standards': showStandards(activity.id), 'hide_standards': !showStandards(activity.id)}">
      <div class="td icon">
        <div class="svg-wrapper" [ngClass]="{es_icons:language == 'es'}">
          <div class="svg" [ngClass]="{al: activity.id == 'al',
                                       cg: activity.id == 'cg',
                                       ir: activity.id == 'ir',
                                       ll: activity.id == 'll',
                                       mm: activity.id == 'mm',
                                       pd: activity.id == 'pd',
                                       st: activity.id == 'st' }">
           </div>
         </div>

         <div class="st_name">
          <ng-container *ngFor="let item of standardsNames">
            <div *ngIf="item.id == activity.id"> {{ item.name}}</div>
          </ng-container>
         </div>
      </div>

      <div  class="td content" *ngFor="let item of activity.items" (click)="vmOnSelected(activity.id)">
        <div class="activity" *ngFor="let element of item.items">
          <p class="titleActivity">{{'weekly_overview.activity_name' | translate}}:</p>
          <ul class="plan-list">
            <li *ngFor="let name of element.name" [ngClass]="{withoutPoint: element.name.length == 1}"> {{  name }}</li>
          </ul>
          <p class="titleActivity">{{'weekly_overview.objective' | translate}}:</p>
          <ul class="plan-list">
            <li *ngFor="let objectives of element.objectives" [ngClass]="{withoutPoint: element.objectives.length == 1}">{{  objectives }}</li>
          </ul>
        </div>

        <div class="standards">
          <ng-container *ngFor="let item of standardsData">
            <div *ngIf="item.id == activity.id"> 
              <ul>
                <li class="withoutPoint" *ngFor="let element of item.standards"> {{element}} </li>
              </ul> 
            </div>
          </ng-container>
         </div>
      </div>
    </div>

    <div class="tr print-notes">
      <div class="td icon" scope="row">
        <div class="svg-wrapper">
          <div class="svg png">
            <img *ngIf="language == 'en'" class="notes" src="../../../../../assets/icons/notes_en.png" alt="lbp-notes-icon">
            <img *ngIf="language == 'es'" class="notes" src="../../../../../assets/icons/notes_es.png" alt="lbp-notes-icon">
           </div>
         </div>
      </div>
      <div class="td activity">
      </div>
    </div>
  </tbody>
</table>
<!--table end-->


<div class="container-lg loader" *ngIf="loader">
  <loader></loader>
</div>