import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'teacher-reflections',
  templateUrl: './reflections.component.html',
  styleUrls: ['./reflections.component.scss']
})

export class ReflectionsComponent implements OnInit {

  public title: string = 'Teacher Reflections';
  public school: any;
  public age: string = null;
  public week: number = null;  
  public day: number = null;
  public areas: any;
  public classes: any;
  public language: string;  
  public reflections: any[] = null;
  public loading:boolean = true;
  
  constructor(private appState: AppState,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private teacherService: TeacherService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {

    
    this.age = this.appState.restore('age');
    this.day = this.appState.restore('day');
    this.week = this.appState.restore('week');

    if (!this.age) {
      this.appState.store('age', 'in', true);
      this.age = this.appState.get('age');
    }
    
    this.areas = this.teacherService.getActivitiesInAge(this.age)

    if (!this.week) {
        this.appState.store('week', 1, true);
        this.week = this.appState.get('week');
    }

    if (!this.day) {
      this.appState.store('day', 1, true);
      this.day = this.appState.get('day');
    }

    this.school = this.appState.restore('school');
    
    this.language = this.translateService.currentLang;
    this.load(this.week, this.day);
  }

  public weekChanged(week) {

    this.loading = true;   
    this.week = week;
    this.load(this.week, this.day);
  }

  public dayChanged(day) {

    this.loading = true;   
    this.day = day;
    this.load(this.week,this.day );
  } 

  public _ageChanged(age) {
    this.loading = true;   
    this.age = age;
    this.load(this.week,this.day);
    this.areas = this.teacherService.getActivitiesInAge(this.age)
  }

  public select(classId: string, area: string) {

    this.appState.store('day', this.day, true);
    this.appState.store('week', this.week, true);
    this.appState.store('area', area, true);
    this.router.navigate(['home/tr', classId], { relativeTo: this.activatedRoute.parent });
  }

  private async load(week: number, day: number) {

    const classes = await this.teacherService.getClasses();

    const filteredClasses = classes.filter(element => element.age_group === this.age).sort((a, b) => a.title.localeCompare(b.title))
  
    const reflectionsPromises = filteredClasses.map(item =>
      this.getReflection(item.idnumber, item.title, week, day, item.age_group)
    );
  
    this.reflections = await Promise.all(reflectionsPromises);
    this.loading = false;
  }

  private async getReflection(classId: string, title: string, week: number, day: number, age_group: string) {


    const reflectionsByArea = await Promise.all(
      this.areas.map(area =>
        this.teacherService.getReflections(classId, week, day, area).then(reflections => ({
          area: area,
          reflections: reflections,
        }))
      )
    );
  
    return {
      id: classId,
      title: title,
      age_group: age_group,
      reflections_by_area: reflectionsByArea,
    };
  }
}
