import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { AppState, TeacherService, ContentService, StandardsService } from 'src/app/services';

@Component({
    selector: 'teacher-daily-standards',
    templateUrl: './daily.standards.component.html',
    styleUrls: ['./daily.standards.component.scss']
})
export class DailyStandardsComponent implements OnInit {

    private age: string = null;
    private week: string = null;
    private day: string = null;
    private activity: string = null;

    public template: any;
    public missing: boolean = false;   
    public school: any;

    constructor(private appState: AppState,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private teacherService: TeacherService,
                private contentService: ContentService,
                private standardsService: StandardsService,
                private _location: Location) {
                
        this.age = this.appState.restore('age');
        if (!this.age) {
            this.appState.store('age', 'in', true);
            this.age = this.appState.get('age');
        }

        this.activity = this.activatedRoute.snapshot.params['activity'];
        const daily = this.teacherService.hasDailyView(this.age);

        this.week = this.appState.restore('week');
        if (!this.week) {
            this.appState.store('week', 1, true);
            this.week = this.appState.get('week');
        }

        this.day = this.appState.restore('day');
        if (!this.day) {
            this.appState.store('day', 1, true);
            this.day = this.appState.get('day');
        }
        this.day = daily ? this.appState.get('day') : null;
    }

    public ngOnInit() {
    
      this.school = this.appState.restore('school');
      this.load( this.school, this.activity, this.age, this.week, this.day);
    } 

    public back() {

        this._location.back();
        this.router.navigate(['home/da', this.activity], { relativeTo: this.activatedRoute.parent });
    }

    private load(school, area, age, week, day) {

      const language = this.appState.get('language');

      this.standardsService.getStandards(school.p_state, age.toUpperCase(), language.toUpperCase(), area.toUpperCase(), week, day ?? 0)
        .catch((err) => {
            this.missing = true;
             this.template = null;
          })
          .then((data) => {
            this.missing = false;
            this.template = data;           
          })
  }  
}
