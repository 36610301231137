import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService } from 'src/app/services';
import { ContentService } from 'src/app/services/content.service';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.data_graphs';
  public week: number | null = null;
  public school: ISchool; 
  public reflectionSummary: any;
  public content: any;
  public language: string;
  public answers: any[];
  public loading: boolean = false;
  public disabled: boolean = false;
  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[];
  public listTeachers: ITeacher[];
  public listAreas: any [];
  public weeks: Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public currentArea: any = null;
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;

  constructor(private appState: AppState, 
              private contentService: ContentService, 
              private translateService: TranslateService, 
              private adminService: AdminService,
              private teacherService: TeacherService)   { } 

  ngOnInit(): void {
  
    this.language = this.translateService.currentLang;
    this.school = this.appState.restore('school');  
    this.restoreFilters()
  }

  public restoreFilters(){

    this.loading = true;  

    this.listAgeGroups = this.adminService.getAgeGroup();
    const _currentAgeGroup = this.appState.restore('currentAgeGroup') || null;

    if(_currentAgeGroup) {

      this.currentAgeGroup = this.listAgeGroups.find( ageGroup => ageGroup.key ===  _currentAgeGroup.key) || null; 
      
      if(this.currentAgeGroup) {
        this.listAreas = this.teacherService.getActivitiesInAge(this.currentAgeGroup.key);
        const _area = this.appState.restore('currentArea');
        this.currentArea = _area == 'all' ? null : _area;
        
        this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, true)
      }
    }
    
    this.week = this.appState.restore('currentWeek') || this.weeks[0];
    this.loading = false;
  }

  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);

    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {

    if (event) {

      this.school = event;

      this.currentAgeGroup = null;
      this.appState.remove('currentAgeGroup');
      this.currentTeacher = null;
      this.appState.remove('currentTeacher');
      this.currentClass = null;
      this.appState.remove('currentClass');
      this.currentArea = null;
      this.appState.remove('currentArea');
      this.listAreas= null

      this.appState.remove('currentDay');
      this.week =  this.weeks[0]; 
      this.appState.remove('currentWeek');

      this.content = null;

      this.listAgeGroups = this.adminService.getAgeGroup();
      }
  }

  private getTeachers(schoolId, ageId, type: boolean): void {

    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {

      this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));     
   
      if(type){
        
        const restoredTeacher = this.appState.restore('currentTeacher') || null;

        if(restoredTeacher) {
          if(restoredTeacher.username == 'all'){
            this.currentTeacher = this.listTeachers.length == 1 ?  this.listTeachers[0] : null;
          } else {
              this.currentTeacher = this.listTeachers.find( teacher => teacher.username ===  restoredTeacher.username);
          }
        } else{
          this.currentTeacher = this.listTeachers.length == 1 ?  this.listTeachers[0] : null;
        } 
      
        if(this.currentTeacher)  {
          this.getClasses(); 
        } else {
          this.loading = false;
        }
      } 

      }).catch((error) => {
        console.log('Error get teachers: ', error);
      });
  }

  private getClasses(){ 

    this.loading = true;
    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, this.currentTeacher.username).then((classes: IClass[]) => {
      
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));
      
      const restoredClass = this.appState.restore('currentClass') || null;
    
      if (restoredClass) {
        this.currentClass = restoredClass.idnumber === "all"
          ? (this.listClasses.length === 1 ? this.listClasses[0] : null)
          : this.listClasses.find(_class => _class.idnumber === restoredClass.idnumber);
      } else{
        this.currentClass = this.listClasses.length === 1 ? this.listClasses[0] : null;
      }

      this.loading = false;
    })
  }

  public getAreaTitleKey(area:string){
    return 'topics.'+ area;
  }

  public onChange(type: string){

    if(type == 'ageGroup'){

      this.content = null;
      this.appState.store('currentAgeGroup', this.currentAgeGroup,true);

      this.currentTeacher = null;
      this.appState.remove('currentTeacher');
      this.currentClass = null;
      this.appState.remove('currentClass');

      this.listAreas = this.teacherService.getActivitiesInAge(this.currentAgeGroup.key)
      this.currentArea = this.listAreas.includes(this.currentArea) ? this.currentArea : null;

      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, true)

      if(!this.listAreas.includes(this.currentArea)) this.appState.remove('currentArea');
      
    }
    
    if(type == 'area') {
      this.content = null;
      this.appState.store('currentArea', this.currentArea, true);

    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.appState.store('currentTeacher', this.currentTeacher,true);
      this.appState.remove('currentClass');
      this.getClasses()
    }
    if(type == 'class') {
      this.content = null;
      this.appState.store('currentClass', this.currentClass, true);
    }
    if(type == 'week') {
      this.content = null;
      this.appState.store('currentWeek',this.week, true)
    }
  }

  public load() {

    this.loading = true; 
    this.disabled = true;
    const week = this.week == 0 ?  null : this.week;
    const teacher_username =  this.currentTeacher.username == 'all' ? null : this.currentTeacher.username;
    const class_idnumber =  this.currentClass.idnumber == 'all' ? null : this.currentClass.idnumber;

    this.adminService.getReflectionSummary(this.school.idnumber, this.currentAgeGroup.key,  this.currentArea, teacher_username , class_idnumber, week, null)
    .then((reflectionSummary) => {
        this.reflectionSummary = reflectionSummary;
        this.getContent(this.currentArea);
    })
    .catch((error) => {
      this.disabled = false;
        console.error('Failed to load comments:', error);
    });
  }


  private async getContent(area: string) {

    const language = this.translateService.currentLang;
   
    this.contentService.getReflections(language, area)
      .catch((err) => {
          return  language !== 'en' ? this.contentService.getReflections('en', area) : err;
      })
      .then((content) => {
           
          let reflections = content.body;
          this.content = this.mergeData(this.reflectionSummary.questions, reflections)
          this.disabled = false;
        });
  }

  public mergeData(content: any[], reflections: any[]): any[] {
   
    const mergedData = [];
  
    content.forEach((contentItem) => {
      const reflectionItem = reflections.find(ref => ref.id === contentItem.id);
  
      if (reflectionItem && reflectionItem.type != "OPEN_ENDED") {
      
        const colors = ['#23B14D', '#7092BF', '#8EDE7D', '#FF7F26', '#FFC90D', '#C3C430', '#B852BA', '#1DB3CC', '#25A199'];

        let _answers = reflectionItem.answers.map((answer, index) => {
          const summary = contentItem.answers_summary.find(item => item.id_answer === answer.id) 
            ? contentItem.answers_summary.find(item => item.id_answer === answer.id).quantity 
            : 0;
          return { ...answer, quantity: summary, color: colors[index]  };
        });

        const mergedItem = {
          id: contentItem.id,
          text: reflectionItem.text,
          type: reflectionItem.type,
          answers: _answers,
          quantity: contentItem.quantity
        };
        
        mergedData.push(mergedItem);
      }
    });
  
    this.loading = false;
    return mergedData;
  }

  public goTo(type:string){
  
    if (type == '+'){  
      if (this.week == 52){
        this.week = 1;
      } else{
        this.week++;
      }
    } 
    
    if (type == '-'){
        if (this.week == 1){
          this.week = 52;
        } else{
          this.week--;
        }
    } 
    
    this.appState.store('currentWeek',this.week, true);
    this.load()
  }
}
