import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppState, IResource, TeacherService, ContentService, StandardsService } from 'src/app/services';


@Component({
    selector: 'teacher-daily-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class DailyActivityComponent implements OnChanges {
    
    @Input() public age: string = null;
    @Input() public week: string = null;
    @Input() public day: string = null;
    @Input() public school: any = null;
    @Input() public activity: string = null;

    public template: any = null;
    public standards: any = null;
    public resources: IResource[];
    public missing: boolean = false;
    public loader: boolean = false;
    public classes: any;
    public user_firstname: string;
    public language: string;
    public token: any;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private teacherService: TeacherService,
                private contentService: ContentService,
                private standardsService: StandardsService,
                private appState: AppState,
                private translateService: TranslateService) {
        // empty
    }

    public ngOnChanges() {

      this.appState.store('activity', this.activity, true);

      this.load(this.age, this.week, this.day, this.activity);   
    }

    public openConenctions() {

        this.router.navigate(['home/hc'], { relativeTo: this.activatedRoute.parent });
    }

    public openReflections() {
        if(this.classes.length == 1) {

            this.appState.store('area', this.activity, true);
            const _classID = this.classes[0].idnumber;
            this.router.navigate([`home/tr/${_classID}`], { relativeTo: this.activatedRoute.parent });
        } else{
            
            this.router.navigate(['home/tr'], { relativeTo: this.activatedRoute.parent });
        }
    }
   
    public openChat() {

        let display_name = this.user_firstname;
        let token = this.token;

        (window as any).chatWidget.init({
            token,
            display_name,
            chat_button_id: "chat_button"
        });
    }

    private initChat(content:any) {
        if(this.school.p_state) {
            this.teacherService.chat(this.school.p_state, content + '.xml').then((token) => {
              
                this.token = token;
                const display_name = this.user_firstname;
    
                (window as any).chatWidget.init({
                  token,
                  display_name,
                  chat_button_id: "chat_button"
              });      
            })  
        }
    }

    private getDailyStandards(){

        this.standardsService.getDailyStandards(this.school.p_state, this.age.toUpperCase(), this.language.toUpperCase(), this.activity.toUpperCase(), Number(this.week), Number(this.day) ?? 0)
        .catch((err) => {
             this.missing = true;
             this.standards = null;
          })
          .then((data) => {
            this.missing = false;
            this.standards = data;
          })
      }

    private load(age, week, day, activity) {
    
      this.loader = true;
      this.language = this.translateService.currentLang;
      const profile = this.appState.restore('profile'); 
      this.user_firstname = profile.firstname;

      this.teacherService.getClasses().then((classes) => {
        this.classes = classes;
        this.classes = classes.filter( element => element.age_group == this.age)
      });

      this.getDailyStandards();
      
      this.contentService
          .getDailyActivities(this.language, age, week, day, activity)
          .catch((err) => {
            if (this.language !== 'en') {
                return this.contentService.getDailyActivities('en', age, week, day, activity)
            } else { 
                throw err;
            }
          })
          .then((data) => {

              this.missing = false;
              this.template = data.template;    
              this.loader = false;
              this.resources = data.files.map<IResource>((file) => ({
                title: file.filename,
                data: file.url,
                type: file.type
              }));
              
              const content = this.contentService.getDailyActivitiesContentFolder('en', age, week, day, activity);
              this.initChat(content);
          })
          .catch((err) => {
              
              this.missing = true;
              this.template = null;
              this.loader = false;
          })
  }
}
