import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService, TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})

export class FormsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.completed_forms';
  public day: number = null;
  public week: number = null; 
  public school: ISchool; 

  public content: any[];
  public answers: any[];
  public content_body: any[];
  public loading: boolean = false;
  public disabled: boolean = false;
  public language: string;

  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[] = null;
  public listTeachers: ITeacher[] = null;
  public listAreas: any [] = null;
  public weeks: Number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public days: Number[] = [1,2,3,4,5]
  
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;
  public currentArea: any = null;

  constructor(
    private appState: AppState, 
    private contentService: ContentService, 
    private translateService: TranslateService,
    private teacherService: TeacherService,
    private adminService: AdminService)   { 

  } 

  ngOnInit(): void { 
    this.language = this.translateService.currentLang;
    this.school = this.appState.restore('school');
    this.restoreFilters();
  }

  public restoreFilters(){
    this.loading = true;  

    this.listAgeGroups = this.adminService.getAgeGroup();
    const _currentAgeGroup = this.appState.restore('currentAgeGroup') || null;

    if(_currentAgeGroup) {

      this.currentAgeGroup = this.listAgeGroups.find( ageGroup => ageGroup.key ===  _currentAgeGroup.key) || null; 

      if(this.currentAgeGroup) {

        this.listAreas = this.teacherService.getActivitiesInAge(this.currentAgeGroup.key);
        const _area = this.appState.restore('currentArea');
        this.currentArea = _area == 'all' ? null : _area;
        
        this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, true)
      }
    }
    
    this.day = this.appState.restore('currentDay') || this.days[0];
    this.week = this.appState.restore('currentWeek') || this.weeks[0];
    this.loading = false;
  }

  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);
    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {

    if (event) {
     this.school = event;

      this.currentAgeGroup = null;
      this.appState.remove('currentAgeGroup');
      this.currentTeacher = null;
      this.appState.remove('currentTeacher');
      this.currentClass = null;
      this.appState.remove('currentClass');
      this.currentArea = null;
      this.appState.remove('currentArea');
      this.listAreas= null
      this.content = null;

      this.day = 1;
      this.appState.remove('currentDay');
      this.week = 1;
      this.appState.remove('currentWeek');

      this.content = null;

      this.listAgeGroups = this.adminService.getAgeGroup();
    }
  }

  private getTeachers(schoolId, ageId, type:boolean): void {

    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {

      this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));  
      this.loading = false;

      
      const restoredTeacher = this.appState.restore('currentTeacher') || null;
        
      if(restoredTeacher) {
        if(restoredTeacher.username == 'all'){
          this.currentTeacher = this.listTeachers.length == 1 ?  this.listTeachers[0] : null;
        } else {
            this.currentTeacher = this.listTeachers.find( teacher => teacher.username ===  restoredTeacher.username);
        }
      } else{
        this.currentTeacher = this.listTeachers.length == 1 ?  this.listTeachers[0] : null;
      } 
    
      if(this.currentTeacher)  {
        this.getClasses(); 
      } else {
        this.loading = false;
      }
      
    }).catch((error) => {
        console.log('Error get teachers: ', error);
    });  
  }

  private getClasses(){

    this.loading = true;
    const teacher_username =  this.currentTeacher.username == 'all' ? null : this.currentTeacher.username;

    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, teacher_username).then((classes: IClass[]) => {
      
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));  
      const restoredClass = this.appState.restore('currentClass') || null;

      if (restoredClass) {
        this.currentClass = restoredClass.idnumber === "all"
          ? (this.listClasses.length === 1 ? this.listClasses[0] : null)
          : this.listClasses.find(_class => _class.idnumber === restoredClass.idnumber);
      } else{
        this.currentClass = this.listClasses.length === 1 ? this.listClasses[0] : null;
      }

      this.loading = false;
    })
  }

  public getAreaTitleKey(area:string){
    return 'topics.'+ area;
  }

  public onChange(type: string){

    if(type == 'ageGroup'){

      this.content = null;
      this.appState.store('currentAgeGroup',this.currentAgeGroup,true);
      
      this.currentTeacher = null;
      this.appState.remove('currentTeacher');
      this.currentClass = null;
      this.appState.remove('currentClass');

      this.listAreas = this.teacherService.getActivitiesInAge(this.currentAgeGroup.key);
      this.currentArea = this.listAreas.includes(this.currentArea) ? this.currentArea : null;

      if(!this.listAreas.includes(this.currentArea)) this.appState.remove('currentArea');
  
      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key, false)
    }
    
    if(type == 'area') {
      this.content = null;
      this.appState.store('currentArea', this.currentArea, true);
    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.appState.store('currentTeacher', this.currentTeacher,true);
      this.appState.remove('currentClass');
      this.getClasses()
    }
    if(type == 'class') {

      this.content = null;
      this.appState.store('currentClass', this.currentClass, true); 
    }
    if(type == 'week') {

      this.content = null;
      this.appState.store('currentWeek', this.week, true);
    }
    if(type == 'day') {

      this.content = null;
      this.appState.store('currentDay', this.day, true);
    }
  }

  public load() {

    this.loading = true; 
    this.disabled = true; 

    this.adminService.getReflectionsByTeacherClassAreaWeekDay(this.currentTeacher.username, this.currentClass.idnumber, this.currentArea, this.week, this.day)
        .then((answers: any[]) => {

            this.answers = answers;
            this.getContent(this.currentArea, this.week.toString(), this.currentClass.idnumber, this.currentTeacher.username);
        })
        .catch((error) => {
          this.disabled = false; 
            console.error('Error receiving reflection: ', error);
        })
        .finally(() => {
          this.disabled = false; 
           this.loading = false; 
        });
  }

  private async getContent(area: string, week: string, classId: string, teacher: string) {

    const language = this.translateService.currentLang;
   
    this.contentService.getReflections(language, area)
      .catch((err) => {
          return  language !== 'en' ? this.contentService.getReflections('en', area) : err;
      })
      .then((content) => {
            this.content = content.body;
       
            if (this.answers && this.answers[0] && this.answers[0].reflection) {
              let reflections = JSON.parse(this.answers[0].reflection);
              this.updateContentWithReflections(reflections, this.content);
            } 
        });
  }

  private updateContentWithReflections(reflections: any[], content: any) {

    reflections.forEach(reflection => {

        let question = content.find(q => q.id === reflection.id);
        
          if (question.type === 'LIST') {
            reflection.answers?.forEach(answerId => {
              let answer = question.answers.find(a => a.id === answerId);
              if (answer) answer.value = true; 
            });
          }
  
          if (question.type === 'OPEN_ENDED') {
            question.value = reflection.text;
          }
          
          this.disabled = false; 
          this.loading = false;
    });
  }

  public goTo(type:string){
    if (type == '+'){
      if (this.day == 5){
        this.day = 1;
        if (this.week == 52){
          this.week = 1;
        } else{
          this.week++;
        }
      } else{
        this.day++;
      }
  }
  if (type == '-'){
    if (this.day == 1){
      this.day = 5;
      if (this.week == 1){
        this.week = 52;
      } else{
        this.week--;
      }
    } else{
      this.day--;
    }
  }  
  
  this.appState.store('currentDay',this.day, true);
  this.appState.store('currentWeek',this.week, true);
  this.load()
  }
}
