
  <div class="container-lg">
    <section class="daily-standarts-comp">
      <div class="container-fluid">

        <div class="row mb-1 title-row">
          <p>{{'standards.title' | translate}}</p>  
        </div>

        <ng-container *ngIf="tableData && tableData.length > 0"> 
          <div class="row mb-1" *ngFor="let row of tableData">
            <div class="col">{{ row.text }}</div>
          </div>
        </ng-container>

        <div class="row mb-1" *ngIf=" !tableData || tableData.length == 0">
          <div class="col"> {{'standards.no_standards' | translate}} </div>
        </div>
        
      </div>
    </section>
  </div>
  
