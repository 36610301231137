<div class="wrapper">
    <div class="mb-3">
        <app-menu [mode]="'weekly_overview.title'" [details]="''" [school]="school"></app-menu> 
        <app-filters [showWeek]="true" [showAge]="true" (weekChanged)="weekChanged($event)" (_ageChanged)="_ageChanged($event)"></app-filters>
      
    </div>
    
    <div class="container-lg" *ngIf="!loader">
        <div class="_row">
         
            <div class="print_btn" (click)="print()"> 
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.59 154.29">
                    <defs>
                      <style>
                        .cls-11 {
                          fill: #004ab2;
                        }
                  
                        .cls-22 {
                          fill: #74d1e6;
                        }
                  
                        .cls-33 {
                          fill: #fefefe;
                        }
                  
                        .cls-44 {
                          fill: #31c73b;
                        }
                      </style>
                    </defs>
                    <g>
                      <path class="cls-11" d="M64.7,142.44c-11.45,0-22.9,0-34.35,0A11.11,11.11,0,0,1,25.46,141a14.43,14.43,0,0,1-7.56-9,13.76,13.76,0,0,1-.53-4.06q0-36.84,0-73.69A14.53,14.53,0,0,1,27.2,40.59,15.32,15.32,0,0,1,32,39.88c10.91,0,21.83,0,32.75,0V62.79H33.75v7H205.51v-7h-31V39.87c11,0,22,0,33.08,0a13.9,13.9,0,0,1,11.13,5.27,12.79,12.79,0,0,1,3,7.24c.09,1.21.21,2.43.21,3.64q0,35.78,0,71.56a14.78,14.78,0,0,1-2.47,8.54A13.91,13.91,0,0,1,211,142a14.71,14.71,0,0,1-3.31.42q-16.54,0-33.08,0v-40.1a3.25,3.25,0,0,0-.06-.49h-9a5.69,5.69,0,0,0-.71-.07q-45,0-89.94,0c-.26,0-.53,0-.79.06H64.7v40.6ZM203.59,86.76a8.47,8.47,0,1,0-8.66,8.39A8.4,8.4,0,0,0,203.59,86.76Z" transform="translate(-17.36 -13.36)"/>
                      <path class="cls-22" d="M174.52,39.87V62.76h31v7H33.75v-7h31V13.36H174.52ZM74.16,21.94v.8q0,19.74,0,39.48c0,.65.19.82.83.82q44.93,0,89.85,0c.24,0,.47,0,.68,0v-41Z" transform="translate(-17.36 -13.36)"/>
                      <path class="cls-22" d="M165.58,101.85h9a3.25,3.25,0,0,1,.06.49v65.31H64.7V101.84h9.44c0,.31,0,.62,0,.93v54.75c0,.88,0,.88.87.88h90.54V102.78C165.57,102.47,165.58,102.16,165.58,101.85Z" transform="translate(-17.36 -13.36)"/>
                      <path class="cls-33" d="M165.58,101.85c0,.31,0,.62,0,.93V158.4H75c-.87,0-.87,0-.87-.88V102.77c0-.31,0-.62,0-.93.26,0,.53-.06.79-.06q45,0,89.94,0A5.69,5.69,0,0,1,165.58,101.85Z" transform="translate(-17.36 -13.36)"/>
                      <path class="cls-44" d="M203.59,86.76a8.47,8.47,0,1,1-8.39-8.51A8.36,8.36,0,0,1,203.59,86.76Z" transform="translate(-17.36 -13.36)"/>
                      <path class="cls-33" d="M74.16,21.94h91.35V63c-.21,0-.44,0-.68,0Q119.9,63,75,63c-.64,0-.83-.17-.83-.82q0-19.74,0-39.48Z" transform="translate(-17.36 -13.36)"/>
                    </g>
                </svg>
            </div>
          </div>

        <div class="_row print">
          <div class="print-title">
            <div>{{'weekly_overview.title' | translate}}</div>
            <div class="dash" *ngIf="age">–</div>
            <div *ngIf="age">{{ getAgeTitle(age) | translate}}</div>
            <div class="dash" *ngIf="age && week">–</div>
            <div *ngIf="week">{{'shared.week' | translate}}: {{ week }}</div>
          </div>
        </div>
        
        <app-lesson-plans *ngIf="template" [school]="school" [xml]="template" [age]="age" [week]="week" [mode]="mode" (selected)="selectedActivity($event)"></app-lesson-plans>
        <div *ngIf="missing">{{'shared.missing' | translate}}</div>
    </div>
    <div class="container-lg loader" *ngIf="loader">
        <loader></loader>
    </div>
</div>
