


<table>
  <thead>
    <div class="print-title">
      <div>{{'weekly_overview.title' | translate}}</div>
      <div class="dash" *ngIf="age">–</div>
      <div *ngIf="age">{{ ageTitle | translate}}</div>
      <div class="dash" *ngIf="age && week">–</div>
      <div *ngIf="week">{{'shared.week' | translate}} {{ week }}</div>
    </div>

    <div class="trColor" *ngFor="let row of tableData; let i = index">
      <div class="myWrapper header" *ngIf="i === 0">
        <div class="AsSvg-wrapper wCell"></div>
        <div class="wrapTr">
          <div class="flColumn">
           
            <div class="wCell" *ngFor="let day of ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5']">
              {{ 'shared.day' | translate }} {{day.slice(4)}}
            </div>

          </div>
        </div>
      </div>
    </div>
  </thead>
  
  <tbody>
    <div *ngFor="let row of tableData; let i = index" class="trColor">
      <div class="myWrapper">
        <div class="svg-wrapper wCell" [ngClass]="{es_icons:language == 'es'}">
          <div class="svg" [ngClass]="{al: row.id == 'al',
                                      cg: row.id == 'cg',
                                      ir: row.id == 'ir',
                                      ll: row.id == 'll',
                                      mm: row.id == 'mm',
                                      pd: row.id == 'pd',
                                      st: row.id == 'st' }">
          </div>
        </div>   
        
        <div class="wrapTr">
          <div class="flColumn IfPaddingBottom">
            <div class="wCell" *ngFor="let day of ['day1', 'day2', 'day3', 'day4', 'day5']; let i=index;" (click)="vmOnSelected(row.id, i)">
              <div *ngFor="let item of row[day]">
                <p class="titleActivity">{{'weekly_overview.activity_name' | translate}}:</p>
                <ul class="plan-list">
                  <li *ngFor="let name of item.name" [ngClass]="{withoutPoint: item.name.length == 1}">{{ name }}</li>
                </ul>          
              </div>
            </div>
          </div>

          <div class="flColumn IfPaddingTop">
            <div class="wCell" *ngFor="let day of ['day1', 'day2', 'day3', 'day4', 'day5']; let i=index;" (click)="vmOnSelected(row.id, i)">
              <div *ngFor="let item of row[day]" >
                <p class="titleActivity objectiveTitle">{{'weekly_overview.objective' | translate}}:</p>
                <ul class="plan-list objectives">
                  <li class="" *ngFor="let objectives of item.objectives" [ngClass]="{withoutPoint: item.objectives.length == 1}">
                    {{ objectives }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  class="myWrapper" *ngIf="showStandards()">
        <div class="wCell">
          <div class="standard_name"> 
            <ng-container *ngFor="let item of standardsNames">
              <div *ngIf="item.id == row.id"> {{ item.name}}</div>
            </ng-container>
          </div>
        </div>  

        <div class="wrapTr">
          <div class="flColumn IfPaddingBottom">
            <div class="wCell standards_list" *ngFor="let day of [1,2,3,4,5]" (click)="vmOnSelected(row.id, i)">
              <ng-container *ngFor="let item of standardsData">
                <div *ngIf="item.id == row.id && item.day == day"> 
                  <ul>
                    <li class="withoutPoint" *ngFor="let element of item.standards"> {{element}} </li>
                  </ul> 
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="trColor print-notes">
      <div class="myWrapper">
        <div class="svg-wrapper wCell png-icon">
          <div class="png">
            <img *ngIf="language == 'en'" class="notes" src="../../../../../assets/icons/notes_en.png" alt="lbp-notes-icon">
            <img *ngIf="language == 'es'" class="notes" src="../../../../../assets/icons/notes_es.png" alt="lbp-notes-icon">
          </div>
        </div>    
        <div class="wrapTr">
          <div class="flColumn">
            <div class="wCell" *ngFor="let day of ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5']">
            </div>
          </div>
        </div>
      </div>
    </div>
  </tbody>
</table>

<div class="container-lg loader" *ngIf="loader">
  <loader></loader>
</div>